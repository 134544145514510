import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Paragraph from '../components/blocks/Paragraph';
import Strong from '../components/blocks/Strong';
import PageTitle from '../components/blocks/PageTitle';

const PrivatePolicyPage = ({ data }) => (
    <Layout>
        <div className='text-text space-y-sm py-lg'>
            <div className='max-w-xl md:mx-auto sm:text-center lg:max-w-2xl'>
                <PageTitle>Polityka Prywatności</PageTitle>
            </div>
            <div className="space-y-sm">
                <Paragraph>
                    Klauzula informacyjna o przetwarzaniu danychNa podstawie art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.4.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), informuję, że:
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Administrator danych:</Strong>
                    Administratorem danych osobowych jest Komis Płytowy Królestwo Al. NMP 3, 42-200 Częstochowa
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Cel zbierania danych osobowych</Strong>
                    Przetwarzanie Państwa danych osobowych odbywa się na podstawie art. 6 RODO i w celach marketingowych , Administrator Danych Osobowych powołuje się na prawnie uzasadniony interes, którym jest analityka internetowa strony www, analiza odwiedzin itp.
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Okres przechowywania danych osobowych:</Strong>
                    Państwa dane osobowe będą przechowywane przez okres od nawiązania współpracy przez okres 5 lat po jej rozwiązaniu.
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Prawo dostępu do danych osobowych:</Strong>
                    Posiadają Państwo prawo dostępu do treści swoich danych osobowych prawo do ich sprostowania.
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Prawo do usunięcia danych:</Strong>
                    Możecie Państwo w każdej chwili usunąć jakiekolwiek dane przetwarzane przez Komis Płytowy Królestwo Al. NMP 3, 42-200 Częstochowa, z wyjątkiem następujących sytuacji:
                    <br/>
                    - otwarte zamówienie/umowa, które nie zostały jeszcze zakończone lub tylko częściowo.
                    <br />
                    - nieuregulowany dług wobec Komis Płytowy Królestwo Al. NMP 3, 42-200 Częstochowa, niezależnie od metody płatności.
                    <br />
                    - Państwa dług został sprzedany firmie zewnętrznej w ciągu ostatnich trzech lat lub jednego roku (w przypadku klientów, którzy zmarli)
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Prawo wniesienia skargi do organu nadzorczego:</Strong>
                    Przysługuje Państwu prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy uznają Państwo, iż przetwarzanie Państwa danych osobowych dotyczących narusza przepisy RODO.
                    Podanie przez Państwa danych osobowych jest wymogiem warunkiem zawarcia umowy.
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Podstawy prawne przetwarzania danych</Strong>
                    W przypadku przetwarzania uzyskanych od Państwa danych osobowych każdorazowo poinformujemy Cię, czy zostały one przekazane w sposób regulaminowy, czy jest wymagane podpisanie umowy oraz czy masz obowiązek przekazać swoje dane osobowe i jakie są możliwe konsekwencje niewyrażenia na to zgody.
                </Paragraph>
                <Paragraph>
                    <Strong className={"block"}>Zautomatyzowane podejmowanie decyzji, profilowanie:</Strong>
                    Państwa dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą profilowane.
                </Paragraph>
                <Link className="button-main" to="/">Home</Link>
            </div>
        </div>
    </Layout>
);

export default PrivatePolicyPage;
