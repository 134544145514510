import React from 'react';

const Strong = ({ children, className }) => {

    return (
        <strong className={`text-base lg:text-lg leading-6 lg:leading-7 font-medium${` `}${className}`}>
            {children}
        </strong>
    )
}

export default Strong